import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ModelDataTypeStringArray } from "components-care";
import { FormHelperText } from "@mui/material";
import RegionSelector from "../../../components/RegionSelector/RegionSelector";
class RendererRegionSelect extends ModelDataTypeStringArray {
    render(params) {
        const { visibility, field, value, label, handleChange, handleBlur, errorMsg, } = params;
        if (visibility.disabled)
            return _jsx(_Fragment, {});
        if (visibility.hidden) {
            return (_jsx("input", { type: "hidden", name: field, value: this.stringify(value), readOnly: true, "aria-hidden": "true" }));
        }
        if (visibility.editable) {
            if (visibility.grid)
                throw new Error("Not supported");
            return (_jsxs(_Fragment, { children: [_jsx(RegionSelector, { label: label, selected: value, readOnly: visibility.readOnly, onChange: handleChange, name: field, onBlur: handleBlur }), _jsx(FormHelperText, { error: !!errorMsg, children: errorMsg })] }));
        }
        throw new Error("RendererRegionSelect: Pure view not supported");
    }
}
export default RendererRegionSelect;
