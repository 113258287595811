import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { DialogContextProvider, PermissionContextProvider, } from "components-care";
import TenantContext from "../../components/TenantContext";
import TenantContextSetter from "../../components/TenantContextSetter";
const MyTenantContext = (props) => {
    const { tenantId, children } = props;
    return (_jsx(PermissionContextProvider, { children: _jsx(TenantContext, { children: _jsx(TenantContextSetter, { tenantId: tenantId, children: _jsx(DialogContextProvider, { children: children }) }) }) }));
};
export default React.memo(MyTenantContext);
