import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TypeEmbeddedDeviceTags from "../TypeEmbeddedDeviceTags";
class RendererEmbeddedDeviceTags extends TypeEmbeddedDeviceTags {
    render(params) {
        const { visibility, field, value, label } = params;
        if (visibility.disabled)
            return _jsx(_Fragment, {});
        if (visibility.hidden) {
            return (_jsx("input", { type: "hidden", name: field, value: this.stringify(value), readOnly: true, "aria-hidden": "true" }));
        }
        if (visibility.editable)
            throw new Error("Not supported");
        return (_jsxs(_Fragment, { children: [!visibility.grid && `${label}: `, this.stringify(value)] }));
    }
}
export default RendererEmbeddedDeviceTags;
