import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "components-care";
const FacilityDataTitle = () => {
    const { t } = useTranslation("tenant");
    const { id, values } = useFormContext();
    return (_jsx(_Fragment, { children: [id ? t("pagetitle") : t("pagetitle-create"), values.name]
            .filter(Boolean)
            .join(" • ") }));
};
export default React.memo(FacilityDataTitle);
