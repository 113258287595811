import getLocalizedString from "../../utils/getLocalizedString";
class TypeEmbeddedDeviceTags {
    validate(value) {
        if (!Array.isArray(value))
            return "not an array";
        return null;
    }
    getFilterType() {
        return null;
    }
    getDefaultValue() {
        return [];
    }
    stringify(value) {
        return value.map((tag) => getLocalizedString(null, tag.labels)).join(", ");
    }
}
export default TypeEmbeddedDeviceTags;
