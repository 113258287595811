import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Grid } from "@mui/material";
import { FormField } from "components-care";
import DeviceModelUploads from "./DeviceModelUploads";
import DeviceModelVideosEdit from "./DeviceModelVideosEdit";
const TabRegulatory = () => {
    return (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "available_in_countries" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "regulatories" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(DeviceModelUploads, { category: "regulatory" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(DeviceModelVideosEdit, { category: "regulatory" }) })] }));
};
export default React.memo(TabRegulatory);
