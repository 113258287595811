import { jsx as _jsx } from "react/jsx-runtime";
import { ModelDataTypeEnumMultiSelectRenderer } from "components-care";
import { GroupBox } from "components-care/dist";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()((theme) => ({
    ul: {
        paddingLeft: theme.spacing(3),
    },
}));
const ContactCategoriesReadOnlyView = (props) => {
    const { enumValues, selected } = props;
    const { t } = useTranslation("contact");
    const { classes } = useStyles();
    return (_jsx(GroupBox, { label: t("categories.field"), children: selected.length === 0 ? (t("categories.no-selected")) : (_jsx("ul", { className: classes.ul, children: enumValues
                .filter((value) => selected.includes(value.value))
                .map((entry) => (_jsx("li", { children: entry.getLabel() }, entry.value))) })) }));
};
class RendererContactCategories extends ModelDataTypeEnumMultiSelectRenderer {
    render(params) {
        if (params.visibility.readOnly) {
            return (_jsx(ContactCategoriesReadOnlyView, { enumValues: this.values, selected: params.value }));
        }
        return super.render(params);
    }
}
export default RendererContactCategories;
