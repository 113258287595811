import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Grid, Link, Typography } from "@mui/material";
import { FormField, getValueByDot, useFormContext } from "components-care";
import { StripePublishableKey } from "../../../../../constants";
const FacilityAdminStatistics = () => {
    const { model, values } = useFormContext();
    const renderStat = (field, link) => {
        return (_jsxs(Typography, { children: [_jsx("b", { children: model.fields[field].getLabel() }), ":", " ", link ? (_jsx(Link, { rel: "noopener", target: "_blank", href: link, children: getValueByDot(field, values) })) : getValueByDot(field, values)] }));
    };
    return (_jsxs(Grid, { container: true, spacing: 1, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "trust_level" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "exclude_from_bi" }) }), _jsx(Grid, { item: true, xs: 12, children: renderStat("tenant_id") }), StripePublishableKey && values.stripe_customer_id && (_jsx(Grid, { item: true, xs: 12, children: renderStat("stripe_customer_id", `https://dashboard.stripe.com${StripePublishableKey.includes("_test_") ? "/test" : ""}/customers/${values.stripe_customer_id}`) })), _jsx(Grid, { item: true, xs: 12, children: renderStat("debtor_number") }), _jsx(Grid, { item: true, xs: 12, children: renderStat("statistic.inventories") }), _jsx(Grid, { item: true, xs: 12, children: renderStat("statistic.issues") }), _jsx(Grid, { item: true, xs: 12, children: renderStat("statistic.staffs") }), _jsx(Grid, { item: true, xs: 12, children: renderStat("statistic.briefings") })] }));
};
export default React.memo(FacilityAdminStatistics);
