import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Grid, Tooltip, Typography, useMediaQuery, useTheme, } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { combineClassNames } from "components-care";
import { customColors } from "../../../../../theme";
const useStyles = makeStyles()((theme) => ({
    root: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        textAlign: "center",
        lineHeight: 0,
        padding: `4px ${theme.spacing(1)}`,
    },
    rootColorRed: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },
    rootColorYellow: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
    },
    rootColorBlue: {
        backgroundColor: customColors.turquoise,
        color: theme.palette.getContrastText(customColors.turquoise),
    },
    rootColorGray: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
    },
    rootXs: {
        padding: 4,
    },
    rootDense: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        textAlign: "center",
        padding: 1,
        marginLeft: 4,
        lineHeight: 0,
    },
    rootDenseText: {
        lineHeight: "20px",
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 3,
    },
    icon: {
        "& > img": {
            width: "auto",
            height: theme.spacing(3),
            objectFit: "contain",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            position: "relative",
        },
    },
    label: {
        marginLeft: theme.spacing(1),
        height: "100%",
        lineHeight: theme.spacing(3),
    },
}));
const TagRenderer = (props) => {
    const { dense, denseLabel, icon, info, color, children } = props;
    const { classes } = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down("md"));
    if (dense) {
        if (!icon && !denseLabel)
            throw new Error("Icon required for dense");
        return (_jsx("div", { className: combineClassNames([
                classes.rootDense,
                color === "red" && classes.rootColorRed,
                color === "yellow" && classes.rootColorYellow,
                color === "blue" && classes.rootColorBlue,
                color === "gray" && classes.rootColorGray,
                classes.icon,
                denseLabel && classes.rootDenseText,
            ]), children: denseLabel ? children : _jsx(Tooltip, { title: info, children: icon }) }));
    }
    const content = (_jsxs(Grid, { container: true, className: combineClassNames([
            classes.root,
            color === "red" && classes.rootColorRed,
            color === "yellow" && classes.rootColorYellow,
            color === "blue" && classes.rootColorBlue,
            color === "gray" && classes.rootColorGray,
            isXs && classes.rootXs,
        ]), justifyContent: isXs ? "space-evenly" : "flex-start", wrap: "nowrap", children: [icon && (_jsx(Grid, { item: true, className: classes.icon, children: icon })), !isXs && (_jsx(Grid, { item: true, children: _jsx(Typography, { className: classes.label, noWrap: true, children: children }) }))] }));
    return info ? _jsx(Tooltip, { title: info, children: content }) : content;
};
export default React.memo(TagRenderer);
