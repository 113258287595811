import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { SERVICE_INTERVAL_CATEGORIES } from "./MaintenanceTypesInput";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()({
    midAlign: {
        verticalAlign: "middle",
    },
});
const MaintenanceTypeCategory = (props) => {
    const { t } = useTranslation("common");
    const { classes } = useStyles();
    const categoryOptions = SERVICE_INTERVAL_CATEGORIES(t, props.midAlign ? classes.midAlign : undefined);
    const category = categoryOptions.find((option) => option.value === props.category);
    return (_jsx(_Fragment, { children: category && (_jsx(Tooltip, { title: category.label, children: _jsx("span", { children: category.icon }) })) }));
};
export default React.memo(MaintenanceTypeCategory);
