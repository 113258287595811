import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from "react";
import { useEnterpriseClientList } from "../utils/useEnterpriseClientList";
import { Typography } from "@mui/material";
import { DialogContextProvider, Loader } from "components-care";
const EnterpriseContext = React.createContext(undefined);
export const useEnterpriseContext = () => {
    const ctx = useContext(EnterpriseContext);
    if (!ctx)
        throw new Error("EnterpriseContext not set");
    return ctx;
};
export const EnterpriseContextProvider = React.memo((props) => {
    const { isLoading, data, error } = useEnterpriseClientList();
    if (error)
        return _jsx(Typography, { color: "error", children: error.message });
    if (isLoading || !data)
        return _jsx(Loader, {});
    return (_jsx(EnterpriseContext.Provider, { value: {
            clients: data[0],
        }, children: _jsx(DialogContextProvider, { children: props.children }) }));
});
export default EnterpriseContext;
