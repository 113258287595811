import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Divider, Grid, Typography } from "@mui/material";
import { FormField } from "components-care";
import { useTranslation } from "react-i18next";
const FacilityContactInformationTab = () => {
    const { t } = useTranslation("tenant");
    return (_jsxs(Grid, { container: true, spacing: 2, children: [_jsxs(Grid, { item: true, xs: 12, container: true, spacing: 1, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "subtitle1", children: t("facility_contact.title") }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Divider, {}) }), _jsxs(Grid, { item: true, xs: 12, container: true, spacing: 2, children: [_jsxs(Grid, { item: true, xs: 12, md: 6, container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "facility_contact.first_name" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "facility_contact.last_name" }) })] }), _jsxs(Grid, { item: true, xs: 12, md: 6, container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "facility_contact.phone" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "facility_contact.email" }) })] })] })] }), _jsxs(Grid, { item: true, xs: 12, container: true, spacing: 1, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "subtitle1", children: t("billing_contact.title") }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Divider, {}) }), _jsxs(Grid, { item: true, xs: 12, container: true, spacing: 2, children: [_jsxs(Grid, { item: true, xs: 12, md: 6, container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "billing_contact.first_name" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "billing_contact.last_name" }) })] }), _jsxs(Grid, { item: true, xs: 12, md: 6, container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "billing_contact.phone" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "billing_contact.email" }) })] })] })] })] }));
};
export default React.memo(FacilityContactInformationTab);
