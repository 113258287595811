import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { Checkbox, Grid, Typography } from "@mui/material";
import { CheckboxAddIcon } from "../../../../../components/icons";
import { makeStyles } from "tss-react/mui";
import { combineClassNames } from "components-care";
const RENDERER_SIZE = 24;
const useStyles = makeStyles()((theme) => ({
    line1: {
        height: RENDERER_SIZE / 2,
        width: RENDERER_SIZE / 2,
        borderLeft: "1px solid black",
        borderBottom: "1px solid black",
    },
    line2: {
        height: RENDERER_SIZE / 2,
        width: RENDERER_SIZE / 2,
    },
    line2Next: {
        borderLeft: "1px solid black",
    },
    line3: {
        height: RENDERER_SIZE,
        width: RENDERER_SIZE,
    },
    height: {
        height: RENDERER_SIZE,
    },
    noPadding: {
        padding: 0,
    },
    clickable: {
        cursor: "pointer",
    },
    focused: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 4,
    },
}));
const DeviceTypeCatalogTreeRenderer = (props) => {
    const { expanded, icon, label, hasChildren, onToggleExpanded, expandLocked, id, depth, hasNext, parentHasNext, onClick, onAuxClick, focus, } = props;
    const { classes } = useStyles();
    const handleExpand = useCallback(() => onToggleExpanded(id), [onToggleExpanded, id]);
    const offsetLeft = depth > 0 ? RENDERER_SIZE / 2 : 0;
    return (_jsxs(Grid, { container: true, style: {
            height: 24,
            marginLeft: offsetLeft,
            width: `calc(100% - ${offsetLeft}px)`,
        }, wrap: "nowrap", children: [depth !== 0 && (_jsxs(_Fragment, { children: [parentHasNext.slice(1).map((pHasNext, idx) => (_jsx(Grid, { item: true, children: _jsx("div", { className: combineClassNames([
                                classes.line3,
                                pHasNext && classes.line2Next,
                            ]) }) }, idx))), _jsxs(Grid, { item: true, children: [_jsx("div", { className: classes.line1 }), _jsx("div", { className: combineClassNames([
                                    classes.line2,
                                    hasNext && classes.line2Next,
                                ]) })] })] })), _jsx(Grid, { item: true, onClick: hasChildren ? (expandLocked ? undefined : handleExpand) : onClick, onAuxClick: hasChildren ? undefined : onAuxClick, "data-id": id, className: combineClassNames([
                    classes.height,
                    !hasChildren && onClick && classes.clickable,
                ]), children: _jsx(Checkbox, { className: classes.noPadding, checked: !expanded && hasChildren, indeterminate: expanded, disabled: !hasChildren || expandLocked, checkedIcon: _jsx(CheckboxAddIcon, {}) }) }, "expandable"), icon && (_jsx(Grid, { item: true, className: onClick ? classes.clickable : undefined, onClick: onClick, onAuxClick: onAuxClick, "data-id": id, children: icon }, "icon")), _jsx(Grid, { item: true, xs: true, className: onClick ? classes.clickable : undefined, onClick: onClick, onAuxClick: onAuxClick, "data-id": id, children: _jsx(Typography, { noWrap: true, className: focus ? classes.focused : undefined, children: label }) }, "label")] }));
};
export default React.memo(DeviceTypeCatalogTreeRenderer);
