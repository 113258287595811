import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
export const intervalToString = (props, t) => {
    if (props.value == null)
        return t("common:interval.unknown");
    const unitLabel = t("common:interval." + props.unit + (props.value !== 1 ? "s" : ""));
    return `${props.value} ${unitLabel}`;
};
const Interval = (props) => {
    const { t } = useTranslation("common");
    return _jsx(_Fragment, { children: intervalToString(props, t) });
};
export default React.memo(Interval);
