import * as Sentry from "@sentry/react";
import i18n from "../i18n";
const handleBackendMultiSelectLoadError = (err) => {
    if (err.name === "BackendError") {
        const e = err;
        // backend can't find record -> drop it
        if (e.code === "record_not_found_error")
            return null;
        // no access error -> show user
        if (e.code === "authorization_error")
            return (i18n.t("common:errors.backend-multi-selector.perms-error") ||
                "Access denied");
        Sentry.captureException(err);
        // other backend errors: show user
        return e.message || "Unknown error";
    }
    // network errors & co
    Sentry.captureException(err);
    return (i18n.t("common:errors.backend-multi-selector.load-error") ||
        "Something went wrong" // fallback here ensures that this function will never return falsy value, which would cause the item to be dropped
    );
};
export default handleBackendMultiSelectLoadError;
