class TypeFacilityContentResource {
    validate(_value) {
        return null;
    }
    render(_params) {
        throw new Error("Render not implemented.");
    }
    getFilterType() {
        return null;
    }
    getDefaultValue() {
        return null;
    }
    stringify(value) {
        return JSON.stringify(value);
    }
}
export default TypeFacilityContentResource;
