import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo, useState } from "react";
import territoryContainment from "cldr-data/supplemental/territoryContainment.json";
import { getCountries, } from "../../../../../components/RegionSelector/RegionSelector";
import { useCCCountryTranslations } from "components-care/dist/utils/useCCTranslations";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import { ChevronRight as ChevronRightIcon, ExpandMore as ExpandMoreIcon, Search as SearchIcon, } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TextFieldWithHelp } from "components-care";
const territoryGrouping = territoryContainment.supplemental
    .territoryContainment;
const countryGrouping = Object.fromEntries(territoryGrouping["001"]._contains.map((region) => [
    region,
    getCountries(region),
]));
const GroupRenderer = (props) => {
    const { countries, group, search } = props;
    const { t: countriesT } = useCCCountryTranslations();
    const countriesToRender = countryGrouping[group].filter((country) => countries.includes(country) &&
        (!search || countriesT(country).toLowerCase().includes(search)));
    return useMemo(() => (_jsx(_Fragment, { children: countriesToRender.length > 0 && (_jsx(TreeItem, { itemId: group, label: countriesT(group), children: countriesToRender.map((country) => (_jsx(TreeItem, { itemId: country, label: countriesT(country) }, country))) }, group)) })), [countriesT, countriesToRender, group]);
};
const searchInputProps = {
    startAdornment: _jsx(SearchIcon, {}),
};
const TreeViewSelection = [];
const DeviceModelViewerTabRegulatoryCountryList = (props) => {
    const { countries } = props;
    const { t } = useTranslation("device-models");
    // expansion
    const [expanded, setExpanded] = useState([]);
    const handleExpansion = useCallback((_evt, itemId, isExpanded) => {
        setExpanded((prev) => isExpanded ? [...prev, itemId] : prev.filter((x) => x !== itemId));
    }, []);
    // search
    const [search, setSearch] = useState("");
    const searchStr = search.toLowerCase();
    const handleSearchChange = useCallback((evt) => {
        setSearch(evt.target.value);
        setExpanded(evt.target.value ? Object.keys(countryGrouping) : []);
    }, []);
    return (_jsxs(Grid, { container: true, spacing: 2, direction: "column", children: [_jsx(Grid, { item: true, children: _jsx(TextFieldWithHelp, { value: search, onChange: handleSearchChange, InputProps: searchInputProps, placeholder: t("viewer.regulatory.countries.search.placeholder"), fullWidth: true }) }), _jsx(Grid, { item: true, xs: true, children: _jsx(SimpleTreeView, { slots: {
                        collapseIcon: ExpandMoreIcon,
                        expandIcon: ChevronRightIcon,
                    }, expandedItems: expanded, selectedItems: TreeViewSelection, onItemExpansionToggle: handleExpansion, children: Object.keys(countryGrouping).map((group) => (_jsx(GroupRenderer, { countries: countries, group: group, search: searchStr }, group))) }) })] }));
};
export default React.memo(DeviceModelViewerTabRegulatoryCountryList);
