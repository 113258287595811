import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Grid, Typography } from "@mui/material";
import PermissionBlock from "./PermissionBlock";
import { makeStyles } from "tss-react/mui";
import BoxGrid from "../../../../../components/BoxGrid";
const useStyles = makeStyles()({
    title: {
        marginLeft: 8,
    },
});
const PermissionGroup = (props) => {
    const { title, blocks, selected, setSelected } = props;
    const { classes } = useStyles();
    return (_jsxs(BoxGrid, { item: true, xs: true, container: true, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "overline", className: classes.title, children: _jsx("b", { children: title }) }) }), blocks.map((entry) => (_jsx(Grid, { item: true, xs: 12, children: _jsx(PermissionBlock, { checked: selected.split(",").includes(entry.id), onChecked: setSelected, name: entry.id, title: entry.label, description: entry.description }) }, entry.id)))] }));
};
export default React.memo(PermissionGroup);
