import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from "react";
import { useFormContext } from "components-care";
import { useTranslation } from "react-i18next";
const DeviceModelRiskLevelInfo = () => {
    const { t } = useTranslation("device-models");
    const { values } = useFormContext();
    const riskLevel = values.risk_level;
    return useMemo(() => (_jsx(_Fragment, { children: riskLevel && (_jsx("em", { children: _jsx("small", { children: t(`risk-level.enum-description.${riskLevel}`) }) })) })), [t, riskLevel]);
};
export default React.memo(DeviceModelRiskLevelInfo);
