import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { Grid, Link, styled, Typography } from "@mui/material";
const StyledRoot = styled(Grid)(({ theme }) => ({
    cursor: "pointer",
    "&:hover": {
        backgroundColor: theme.palette.action.hover,
    },
}));
const StyledImage = styled("img")(({ theme }) => ({
    width: "100%",
    height: "100%",
    objectFit: "fill",
    aspectRatio: "16 / 9",
    borderRadius: theme.shape.borderRadius,
}));
const Video = (props) => {
    const { thumbnail, title, url } = props;
    const openLink = useCallback(() => {
        window.open(url);
    }, [url]);
    return (_jsxs(StyledRoot, { container: true, onClick: openLink, wrap: "nowrap", alignItems: "stretch", children: [_jsx(Grid, { item: true, xs: 6, children: thumbnail && _jsx(StyledImage, { src: thumbnail, alt: title }) }), _jsxs(Grid, { item: true, xs: 6, sx: { pl: 2 }, container: true, direction: "column", justifyContent: "space-between", children: [_jsx(Grid, { item: true, children: title }), _jsx(Grid, { item: true, children: _jsxs(Typography, { align: "right", variant: "body2", children: ["Source:", " ", _jsx(Link, { target: "_blank", rel: "noopener norefferrer", href: url, children: new URL(url).hostname })] }) })] })] }));
};
export default React.memo(Video);
