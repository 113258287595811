import React, { useContext } from "react";
const ActiveLanguageContext = React.createContext(undefined);
export const useActiveLanguageContext = () => {
    const ctx = useContext(ActiveLanguageContext);
    if (!ctx)
        throw new Error("ActiveLanguageContext missing");
    return ctx;
};
export const AvailableLanguageContext = React.createContext(undefined);
export const useAvailableLanguageContext = () => {
    const ctx = useContext(AvailableLanguageContext);
    if (!ctx)
        throw new Error("AvailableLanguageContext missing");
    return ctx;
};
export default ActiveLanguageContext;
