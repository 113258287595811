import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import PublishStatus from "./PublishStatus";
import { useFormContext } from "components-care";
const PublishStatusForm = () => {
    const { values } = useFormContext();
    const published = values.published;
    return _jsx(PublishStatus, { status: published ? "published" : "draft" });
};
export default React.memo(PublishStatusForm);
