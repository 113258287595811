import EnterpriseContext from "../components/EnterpriseContext";
import { useFormContext } from "components-care";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
export const useEnterpriseSyncConfigInForm = (setting) => {
    const { t } = useTranslation("common");
    const { values, setCustomReadOnly, removeCustomReadOnly } = useFormContext();
    const { tenant_id } = values;
    const ctx = useContext(EnterpriseContext);
    if (ctx && !tenant_id)
        throw new Error("tenant_id not in form values");
    const sync = (() => {
        if (!ctx)
            return null;
        const client = ctx.clients.find((client) => client.id === tenant_id);
        if (!client)
            throw new Error("Client not found in enterprise client list");
        return client.sync_config[setting];
    })();
    useEffect(() => {
        if (!sync)
            return;
        if (sync !== "to_samedis")
            return;
        setCustomReadOnly("sync", t("sync-disabled.edit"));
        return () => {
            removeCustomReadOnly("sync");
        };
    }, [removeCustomReadOnly, setCustomReadOnly, sync, t]);
    return sync;
};
