import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Grid } from "@mui/material";
import { FormField } from "components-care";
import DeviceModelUploads from "./DeviceModelUploads";
import DeviceModelLocalizedFormFieldProvider from "./DeviceModelLocalizedFormFieldProvider";
import DeviceModelVideosEdit from "./DeviceModelVideosEdit";
const TabService = () => {
    return (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: "with_service_intervals" }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(FormField, { name: "manufacturer_warranty_months" }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(DeviceModelLocalizedFormFieldProvider, { children: _jsx(FormField, { name: "manufacturer_service_url_labels" }) }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(FormField, { name: "end_of_service_at" }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(FormField, { name: "end_of_life_at" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(DeviceModelUploads, { category: "service" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(DeviceModelVideosEdit, { category: "service" }) })] }));
};
export default React.memo(TabService);
