import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from "react";
import { ModelDataTypeStringLocalizedSingleRendererContext } from "components-care";
import { useActiveLanguageContext } from "../../../../../components/ActiveLanguageContext";
const DeviceModelLocalizedFormFieldProvider = (props) => {
    const { children } = props;
    const [language] = useActiveLanguageContext();
    return useMemo(() => (_jsx(_Fragment, { children: language && (_jsx(ModelDataTypeStringLocalizedSingleRendererContext.Provider, { value: language, children: children })) })), [language, children]);
};
export default DeviceModelLocalizedFormFieldProvider;
